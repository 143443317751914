﻿body{
 background: url('../content/images/bg-wood.jpg') repeat center -10px;
}

#layout-wrapper{
   
    width: 100%;
}

.container-fluid{
    max-width: 1200px;
}

.search-form{
 .btn-icon
        {
            background: none;
            border: none;
            padding: 0 5px;
            font-size: 28px;
            line-height: 28px;
        }
}

.layout-header{
    color: #fff;
    text-transform: uppercase;

    .zone-header-left {
        > article, 
        > div {
        display: inline-block;
        vertical-align: middle;

        ul{
            margin-bottom: 0;
        }
    }


        > article:last-child, 
        > div:last-child {
       max-width: 200px;
    }

       

    } 
}

.logo-full{
    background: transparent url('/themes/roskorwell/content/images/logo.svg') no-repeat center center;
}

.no-svg .logo-full{
    background: transparent url('/themes/roskorwell/content/images/logo.png') no-repeat center center;
}

.content-wrapper {
    padding: 30px;

    .row-tiled {
        margin: -30px;
    }
}

.layout-content-background{
    padding-top: 50%;
}

.layout-content-alt {
    color: #fff;

    h1, h2, .h1, .h2 {
        text-align: center;
    }

    .body-copy {
        font-size: $font-size-h4;
        text-align: center;
    }

    .form-field-element {
        color: $brand-primary;
    }

    .content-wrapper, .zone-content-side {
        // margin-left:  -(floor(($grid-gutter-width / 2)));
        // margin-right: -(ceil(($grid-gutter-width / 2)));
        background: transparent url('/themes/roskorwell/content/images/bg-paper-brown.jpg') repeat-y center top;
    }
}


/*.blog, .archives-list, .tagged-posts {

    h2{
        text-align:left;
    }

    p {
        @extend .lead;
        text-align:center;
    }

    .blog-posts, .blog-post {
        
        p {
            text-align: left;
        }

        padding-left: 0;
    }
}*/

.blog {
    h2, .body-copy {
        text-align: left;
    }

    .blog-description {
        @extend .lead;
        text-align: center;
    }

    .blog-post {
        .row [class^="col-"]:first-child {
            padding-bottom: 20px
        }
    }
}
.zone-content-side {
    padding: 30px;

    img {
        margin-bottom:12px;
    }
}

.content-wrapper {
    background-color: #fff;
}

.layout-footer{
    padding: $box-unit 0;
    font-size: $font-size-h3 + 2px;
    color: #fff;
    text-align: center;

    a{
        @include link-variant(#fff,$brand-primary);
    }

    > .container-fluid > .row > div > div{ 
        > article:first-child,
        > div:first-child{
        border-top: 2px solid #fff;
        padding-top: $box-unit;
       
    }}

    > .container-fluid > .row > div{
          margin-bottom: $box-unit;
    }

}



@media (max-width: $screen-xs-max){

    .detail-blog-post {
        .content-wrapper {
            margin: 10px 0;
        }
    }

    .content-wrapper {
        margin-left: -(floor(($grid-gutter-width / 2)));
        margin-right: -(ceil(($grid-gutter-width / 2)));
    }

    .dynamic-form {
        padding: 0 10px;
    }
}

@media (min-width: $screen-sm-min){
    .layout-content-alt{
    .body-copy{
        font-size: $font-size-h2;
    }

    }
}


@media (min-width: $screen-md-min){

    .layout-content-background{
        margin-bottom: -150px;
        padding-top:480px;
    }    
}
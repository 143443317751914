﻿.menu-toggle {
    position: absolute;
    top: 10px;
    right: 15px;
    left: auto;
}



.layout-header,
.layout-navigation {
    a {
        @include link-variant(#fff, $color-apples);
    }

    li {
        @include link-variant-child(#fff, $color-apples);
        @include link-variant-child(#fff, $color-saffron,'[href*=saffron]');
        @include link-variant-child(#fff, $color-lavender,'[href*=lavender]');
    }
}

.layout-navigation {
    font-weight: bold;
}

.menu-social-links,
.mm-listview.menu-social-links,
.layout-header .menu,
.layout-navigation .menu {
    text-align: center;
    @include list-unstyled;
    margin-left: -2.5px;


    > li {
        display: inline-block;
        padding-left: 2.5px;
        padding-right: 2.5px;

        &.current {
            a {
                text-shadow: 2px 2px #000;
            }
        }
    }

    > li {
        //  @include link-variant-child(#fff, $brand-primary);
    }
}


.layout-footer .menu {
    @include list-unstyled();
}

.menu.menu-action-menu {
    > li {
        @include link-variant-child(#000, $brand-primary);

        a {
            display: block;
            padding: 10px;
        }

        &.current {
            a {
                text-shadow: none;
            }
        }
    }

    background: transparent url('../content/images/bg-paper-white.png') no-repeat center bottom;
    background-size: cover;
    padding-bottom: 10px;
}

.layout-navigation {
    font-size: $font-size-h3;
    text-transform: uppercase;
}

@media (min-width: $screen-sm-min) {
    .layout-header .menu,
    .layout-navigation .menu,
    .menu-social-links {
    }

    .layout-navigation .menu {
        display: table;
        table-layout: fixed;
        width: 100%;

        > li {
            display: table-cell;
        }
    }
}

.menu-main-menu {
    .shop-thumbnail {
        display: none;
    }
}

.d-mobile {
    display: flex;
}

.d-not-mobile {
    display: none !important;
}

.menu-main-menu {
    .dropdown {
        padding-bottom: 15px;

        .info-image {
            height: 150px;

            & + a {
                display: block;
            }
        }

        li, li.current {
            p, a {
                color: black;
                text-decoration: none;
                margin-bottom: 0;
                text-shadow: none;
                font-size: 16px;
                font-weight: normal;
            }
        }

        > ul {
            z-index: 999999999;
            background-color: #fff;
            padding: 15px;

            /*& > li:first-child {
                padding: 15px;
                @extend .col-sm-3;
            }

            & > li:nth-child(2) {
                padding: 15px;
                @extend .col-sm-9;
            }*/

            & > li {
                padding: 15px;
                width: 100%;
            }
        }

        .text-center {
            text-align: center;
        }

        @media (min-width: $screen-sm-min) {
            .shop-text {
                display: none;
            }

            .d-mobile {
                display: none !important;
            }

            .d-not-mobile {
                display: block !important;
            }

            .img-caption {
                padding: 10px 0;

                a {
                    line-height: 1.25em;
                }
            }

            ul {
                display: none;
                padding: 0;
            }

            &:hover {
                position: static;

                ul {
                    display: block;
                    position: absolute;
                    list-style: none;
                    width: 100%;
                    top: 100%;
                    left: 0;
                    right: 0;

                    .shop-menu {
                        display: flex;
                        position: static;
                        padding: 0;
                    }

                    li {
                        display: block;
                        width: 100%;

                        a {
                            width: 100%;
                        }
                    }
                }
            }

            .shop-thumbnail {
                padding: 0 15px;

                .shop-icon {
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 150px;

                    p {
                        color: black;
                        text-decoration: none;
                        margin-bottom: 0;
                        text-shadow: none;
                        font-size: 16px;
                    }
                }

                a {
                    text-shadow: none !important;
                }
            }
        }
    }
}

﻿.dynamic-form {
    .form-field-element {
        label {
            font-weight: normal;
        }
    }
}

.boaderless.form-control {
    border: 1px solid transparent;
    box-shadow: none;
}
﻿body{
    font-family: $font-stack-default;
    font-size: $font-size-base;
}

p{
    margin-bottom: 20px;
    line-height: 1.25em;    
}

.font-primary{
    font-family: $font-stack-primary
}


#{headings(1,6)}{

    &:first-child{
        margin-top: 0;
    }

}

#{headings(1,3)}{
  margin-bottom: 12px;
}

#{headings(4,6)}{
  margin-bottom: 10px;
}



﻿[ng\:cloak], [ng-cloak], .ng-cloak {
    display: none !important;
}

.nop-shop {
    padding-top: $box-padding;
}

.flyout-cart {
    position: static;
}


.flyout-cart-summary {

    a {
        color: black;
    }

    margin-left: 10px;

    p {
        margin-bottom: 0;
    }
}

.flyout-cart-content {
    //  border-top: 1px solid #000;
    color: black;
    position: absolute;
    top: 82px;
    right: 0;
    z-index: 999;
    background: #fff;
    padding: $box-padding;
    -webkit-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.36);
    -moz-box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.36);
    box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.36);
    width: 600px;

    .product-name {
        color: black;
    }

    > a {
        display: inline-block !important;
    }
}

.cart-boxes {
    .pi {
        display: inline-block;
        margin: 5px 0;
    }

    .cart-box-message {
        text-align: center;
        margin: -19px -19px 10px -19px;
        padding: 5px 10px;
    }

    .cart-box-warning {
        background-color: $brand-danger;
    }

    .cart-box-complete {
        background-color: $brand-success;
    }
}

.product-add {
    position: relative;
    font-size: 0;

    label, input, button {
        font-size: $font-size-h4;
        line-height: 1em;
        text-align: center;
        font-weight: 300;
        padding: 0.5em;
        height: 3em;
        vertical-align: middle;
        margin-bottom: $box-padding;
    }

    label {
    }

    .product-add-qnt-select,
    .product-add-qnt {
        width: 3em;
        height: 3em;
    }

    .btn-add-to-cart {
        height: 3em;
        color: white;
    }
}

.no-svg .product-add .btn-add-to-cart {
    background: transparent url(../content/images/btn-add.png) no-repeat center center;
}


.step-marker {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    border-radius: 0.75em;
    background-color: #000;
    color: #fff;
    text-align: center;
    margin-right: 0.5em;
}


.saving-wrapper {
    position: relative;
    min-height: 250px;
}

.saving {
    background-color: $color-saving-bg;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    text-align: center;
    padding: 40px;
}

@-webkit-keyframes heartbeat {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    14% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }

    28% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    42% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }

    70% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@-moz-keyframes heartbeat {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    14% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }

    28% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    42% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }

    70% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@-o-keyframes heartbeat {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    14% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }

    28% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    42% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }

    70% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes heartbeat {
    0% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    14% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }

    28% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }

    42% {
        -webkit-transform: scale(1.3);
        -moz-transform: scale(1.3);
        -ms-transform: scale(1.3);
        -o-transform: scale(1.3);
        transform: scale(1.3);
    }

    70% {
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1);
    }
}

@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-o-keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.spinner:not(:required) {
    width: 250px;
    height: 100px;
    background: transparent url(../content/images/logo.png) no-repeat center center;
    background-size: contain;
    display: inline-block;
    margin: auto;
    -webkit-animation: heartbeat 1000ms infinite linear;
    -moz-animation: heartbeat 1000ms infinite linear;
    -ms-animation: heartbeat 1000ms infinite linear;
    -o-animation: heartbeat 1000ms infinite linear;
    animation: heartbeat 1000ms infinite linear;
}


.no-svg .spinner {
    width: 120px;
    height: 120px;
    background: transparent url(../content/images/loader.gif) no-repeat center center;
    display: inline-block;
    margin: auto;
}

.account-frame {
    height: 600px;
    width: 100%;
    border: none;
}

.well-danger {
    background-color: $alert-danger-bg;
}

.well-success {
    background-color: $alert-success-bg;
}


@media (min-width: $screen-md-min) {
    .flyout-cart-summary {
        margin-left: 0;
    }


    .flyout-cart-content {
        top: 35px;
    }
}

.btn-shop {
    background-color: $color-gray;
}

.faux-select {
    position: relative;
    z-index: 10;

    .form-control {
        position: relative;

        span {
            display: block;
        }

        box-shadow: none;
    }

    .form-control, .faux-select-options {
        background: $color-gray;
        color: #000;
        border: 1px solid transparent;
    }

    .faux-select-options {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 0;
        color: #000;
        list-style: none;
        padding: 0;
        z-index: 10;

        li {
            line-height: 1.42857;
            padding: 6px 12px;
            cursor: pointer;
        }

        li.faux-select-selected {
            display: block;
        }
    }

    &.faux-select-open {

        .faux-select-options {
            display: block;
        }

        .form-control {
            height: auto;
        }
    }
}

.shop-box {
    touch-action: manipulation;
    cursor: pointer;
    user-select: none;

    .shop-box-image {
        position: relative;
        padding-bottom: 50px;
        padding-top: 100%;

        @media (min-width: $screen-sm-min) {
            position: relative;
            padding-bottom: 0;
        }
    }

    .shop-box-link {
        padding-top: 15px;
        padding-bottom: 15px;

        a {
            color: black;
            font-size: 18px;
        }
    }

    &:hover, &:focus {
        .shop-box-link a {
            color: $brand-primary;
        }
    }

    .shop-box-meta {
        padding-top: 10px;
        padding-bottom: 10px;

        .green-box {
            margin: 5px;
            background-color: $brand-primary;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
}

.category-header {
    padding: 25px;
    margin: 0px;
    text-align: center;
    color: white;

    .category-image {
        margin: auto;
        width: 50%;
        margin-bottom: 20px;

        @media (min-width: $screen-sm-min) {
            width: 100%;
        }
    }
}

.vcenter {
    display: inline-block;
    vertical-align: middle;
    float: none;
}

.product-meta-description {
    margin-top: $box-padding;
    margin-bottom: $box-padding;
}

.product-product-select {
    margin-bottom: $box-padding;
}

.product-meta {
    margin-top: 25px;

    .tab-pane {
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .nav-tabs {
        > li {

            &:first-child {
                padding-left: 0px;
            }

            &:last-child {
                padding-right: 0px;
            }

            padding-left: 10px;
            padding-right: 10px;

            &.active a {
                color: #000;
                background: darken($color-gray, 10%);

                &:hover, &:focus {
                    color: #000;
                    background: darken($color-gray, 10%);
                }
            }

            a {
                font-size: 18px;
                border: none !important;
                background: $color-gray;
                color: #000;

                &:hover, &:focus {
                    color: #000;
                    background: darken($color-gray, 10%);
                }
            }
        }
    }
}
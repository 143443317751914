﻿
.pad-vert{
    padding-top: $box-unit;
    padding-bottom: $box-unit;
}


.row-grid{

    @include make-row($row-grid-gutter);

    margin-top: $row-grid-gutter;

    *[class*="col-"]{
        padding-left: $row-grid-gutter/2;
        padding-right: $row-grid-gutter/2;
        margin-bottom:$row-grid-gutter;
    }

}

.push-grid{
    margin-bottom:$row-grid-gutter;
}


.row-tiled{

    @include make-row($row-tiled-gutter);

    *[class*="col-"]{
        padding-left: $row-tiled-gutter/2;
        padding-right: $row-tiled-gutter/2;
        margin-bottom:$row-tiled-gutter;
    }

}

.btn{
    text-transform: uppercase;
}

.btn-wide{
    padding-left: $box-unit*2;
    padding-right: $box-unit*2;
}

.text-shadow{
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
}    

.img-responsive-inline{
    @include img-responsive(inline-block); 
}


.search-results{
    @include list-unstyled;


    li + li {
        border-top: 1px solid $brand-primary;
        padding-top: $box-unit;
    }
}

[data-click-target]{
    cursor: pointer;
}

.produce-list {
    font-size: 16px;

    ul {
        padding: 0 10px;
    }
}

.body-copy {
       
    .column.first {
        padding-right: 12px;
        padding-bottom: 10px;
    }

    .column.last {
        padding-left: 12px;
        padding-bottom: 10px;
    }

    ul {
        list-style-position: inside;
    }
}

ul {
    &.content-items {
        list-style-type: none;
        padding-left:0;
    }

    &.archiveMonthList {
        list-style-type: none;
    }
    }

.breadcrumb {
    background: none;

    li {
        &.last {
            a {
                font-weight: bold;
            }
        }
    }
}

.archive-trail {
    padding: $breadcrumb-padding-vertical $breadcrumb-padding-horizontal;
    margin-bottom: $line-height-computed;   

    a:last-child {
        font-weight: bold;
    }
}
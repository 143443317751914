﻿@mixin link-variant-child($color, $hover-color,$selector : ''){

     > a#{$selector} {
         color: $color;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $hover-color;
      }
    }

    &.current > a#{$selector} {
         color: $hover-color;
        // font-weight: $font-weight-regular;
    }

}

@mixin link-variant($color, $hover-color){

      color: $color;

      &:hover,
      &:focus {
        text-decoration: none;
        color: $hover-color;
      }
}


@function headings($from:1, $to:6) {
    @if $from == $to {
        @return 'h#{$from}';
    } @else {
        @return 'h#{$from},' + headings($from+1, $to);
    }
}
﻿$brand-primary: #5E831C;
$text-color: #000;

$font-size-base: 14px;

$font-size-h1: 36px;
$font-size-h2: 24px;
$font-size-h3: 18px;
$font-size-h4: 16px;

$font-stack-default: 'Cambo';
$font-stack-primary: 'BlogScript';

$border-radius-base:        0;
$border-radius-large:       0;
$border-radius-small:       0;

$btn-default-border: #fff;

$color-lavender: #8751D8;
$color-saffron: #ED7C32;
$color-apples: $brand-primary;

$color-recipes: #D5562B;
$color-shop: #00753B;
$color-info: #726658;
$color-gifts-lavender: #895881;
$color-gray: #e5e5e5 ;

$box-unit: 15px;
$row-grid-gutter: 15px;
$row-tiled-gutter: 0;

$btn-primary-color:#000;

$box-padding: 15px;

$color-saving-bg: transparentize(#fff, 0.2);
﻿.feature-tile {
}

.feature-tile-content {
    padding: $box-unit;
    color: #fff;
    font-size: $font-size-h2;
}

.zone-after-content .feature-tile {
    // box-shadow:   0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    header {
        background: transparent url('../content/images/bg-paper-white.png') no-repeat center bottom;
        padding: 15px 10px;
        padding-bottom: 30px;
        margin: (-$box-unit) (-$box-unit) ($box-unit) (-$box-unit);
        text-shadow: none;
        color: #000;

        h2{
           // line-height: 1em;
        }
    }

    footer {
        position: absolute;
        top: auto;
        left: auto;
        right: 0;
        bottom: 0;

        p {
            margin-bottom: 0;
        }
    }
}



//@media (min-width:$screen-sm-min){

.feature-tile {
    position: relative;
    min-height: 300px;
    padding-bottom: 50px;

   h2{
  font-family: $font-stack-primary;
  font-size: $font-size-h1;
  line-height: $font-size-h1 * 1.4;
}

    .feature-tile-content {
    
        padding: $box-unit;
        color: #fff;
        font-size: $font-size-h2;

        a:not(.btn) {
            @include link-variant(#fff, $brand-primary);
        }
    }

    footer {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        bottom: $box-unit;
    }
}







//}

@media (min-width:$screen-sm-min) {


    
.feature-tile {
    position: relative;
    padding-top: 50%;
    padding-bottom: 0;
    .feature-tile-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: $box-unit;
        color: #fff;
        font-size: $font-size-h2;

        a:not(.btn) {
            @include link-variant(#fff, $brand-primary);
        }
    }

    footer {
        position: absolute;
        top: auto;
        left: 0;
        right: 0;
        bottom: $box-unit;
    }
}




    .feature-tile-single {
        padding-top: 100%;
    }

    .feature-tile-double {
        .feature-tile-content {
            left: 50%;
        }
    }



    .zone-after-content .feature-tile.feature-tile-double {
        /*maybe we should switch to percentage gutters for this?*/
        padding-top: 49.032%;

        header {
            margin-right: 50%;
        }

        .feature-tile-content {
            left: 0;
        }
    }
}

﻿@font-face {
    font-family: 'Roskorwell';
    src: url('../content/fonts/roskorwell/Roskorwell.eot?5uce0o');
    src: url('../content/fonts/roskorwell/Roskorwell.eot?5uce0o#iefix') format('embedded-opentype'), url('../content/fonts/roskorwell/Roskorwell.ttf?5uce0o') format('truetype'), url('../content/fonts/roskorwell/Roskorwell.woff?5uce0o') format('woff'), url('../content/fonts/roskorwell/Roskorwell.svg?5uce0o#Roskorwell') format('svg');
    font-weight: normal;
    font-style: normal;
}

.rf {
    /* use !important to prevent issues with browser extensions that change fonts */
   
    display: inline-block;
    vertical-align: middle;

    font-family: 'Roskorwell' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.rf-chevron-down:before {
  content: "\e905";
}

.rf-chevron-up:before {
    content: "\e906";
}

.rf-basket:before {
  content: "\e900";
}
.rf-email:before {
  content: "\e901";
}
.rf-facebook:before {
  content: "\e902";
}
.rf-instagram:before {
  content: "\e903";
}
.rf-search:before {
  content: "\e904";
}




.rf-lg{
    font-size: 42px;
    display: inline-block;
    vertical-align: middle;
}